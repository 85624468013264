const OUT = 1.81;


module.exports = {
  panelCode: 'A',
  lineItems: [
    {
      description: 'FRONT BUMPER',
      panel: 'FRONT-BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER PART D/A',
      panel: 'FRONT-BUMPER',
      subsetOf: ['FRONT BUMPER D/A'],
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.65,
                rrAutoPaintable: true,
              }
            }
          }
        },
        },
        'BONNET|LHF-DOOR|LHF-GUARD|RHF-DOOR|RHF-GUARD': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.65,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
        },
        },
      }
    },
    {
      description: 'FRONT BUMPER RR',
      panel: 'FRONT-BUMPER',
      supersetOf: ['FRONT BUMPER PART D/A'],
      settings: {
        'FRONT-BUMPER': {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.33,
                  rrAutoReplaceable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.87,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER OUT',
      panel: 'FRONT-BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3,
                  paintAutoPaintable: true,
                  paintAutoReplaceable: true,
                }
              }
            },
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: OUT,
                  paintAutoPaintable: true,
                  paintAutoReplaceable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  paintTime: 4.75,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  paintTime: 3.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER LOWER',
      panel: 'FRONT-BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.7,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER UPPER',
      panel: 'FRONT-BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.1,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER LOADING 10%',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER LOADING 25%',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                partPrice: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT BUMPER ADJUSTMENTS',
      settings: {
        'FRONT-BUMPER': {
          '^(?!EAGERS)': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.87,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'PDR FRONT BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH FRONT BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH FRONT BUMPER',
      settings: {
        'FRONT-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
