const OUT = 1.13;


export default side => ({
  panelCode: 'B',
  lineItems: [
    {
      description: `${side}R DOOR`,
      panel: `${side}R-DOOR`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.39,
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR WAIST MOULD`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  isPart: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR FRAME MOULD`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  isPart: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR EXTERNAL HANDLE`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!(EAGERS|PRIXCAR))': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.09,
                  isPart: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.75,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR MOULD`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.28,
                  isPart: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR FRAME SEAL`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.2,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR TRIM AND HANDLE`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.23,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR QTR GLASS BOLTED`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.17,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR VERTICAL MOULD`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  isPart: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR FRAME TAPE`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR FRAME TAPE VERTICAL`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR FRAME TAPE HORIZONTAL`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR WEATHER SHIELD`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR PART D/A`,
      panel: `${side}R-DOOR`,
      subsetOf: [`${side}R DOOR D/A`],
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.63,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
        [`${side}F-DOOR|${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.63,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR D/A`,
      panel: `${side}R-DOOR`,
      supersetOf: [`${side}R DOOR PART D/A`],
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 2.4,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR OUT`,
      panel: `${side}R-DOOR`,
      supersetOf: [`${side}R DOOR BLEND`],
      subsetOf: [`${side}R DOOR IN & OUT`],
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3,
                  paintAutoPaintable: true,
                }
              },
            },
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: OUT,
                  paintAutoPaintable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 5,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR IN & OUT`,
      panel: `${side}R-DOOR`,
      supersetOf: [`${side}R DOOR OUT`, `${side}R DOOR BLEND`],
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.69,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR BLEND`,
      panel: `${side}R-DOOR`,
      subsetOf: [`${side}R DOOR IN & OUT`, `${side}R DOOR OUT`],
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'SEDAN|UTE4DR|VAN|WAGON': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'SEDAN|UTE4DR|VAN|WAGON': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR LOADING 10%`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR LOADING 25%`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR SEALERS NESS`,
      panel: `${side}R-DOOR`,
      settings: {
        [`${side}R-DOOR|${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 30.80,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R DOOR MOULD`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!EAGERS)': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  isPart: true,
                  partPrice: 100,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R DOOR REALIGNMENT`,
      settings: {
        [`${side}R-DOOR`]: {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 100,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.25,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `PDR ${side}R DOOR`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side}R DOOR`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side}R DOOR`,
      settings: {
        [`${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
