module.exports = {
  panelCode: 'D',
  lineItems: [
    {
      description: 'UPPER TAILGATE',
      panel: 'UPPER-TAILGATE',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE PART D/A',
      subsetOf: ['TAILGATE D/A'],
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.35,
                rrAutoPaintable: true,
              }
            },
          },
          },
        },
        'TURRET|LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.45,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE D/A',
      supersetOf: ['TAILGATE PART D/A'],
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 2.45,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE OUT',
      panel: 'UPPER-TAILGATE',
      supersetOf: ['UPPER TAILGATE BLEND'],
      subsetOf: ['UPPER TAILGATE IN & OUT'],
      settings: {
        'UPPER-TAILGATE': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              'HATCH|WAGON|VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.2,
                  paintAutoPaintable: true,
                }
              },
            },
            '^(?!panel)': { /* pricing method */
              'HATCH|WAGON|VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.1,
                  paintAutoPaintable: true,
                }
              },
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.5,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.2,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE IN & OUT',
      panel: 'UPPER-TAILGATE',
      supersetOf: ['UPPER TAILGATE OUT', 'UPPER TAILGATE BLEND'],
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.85,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE BLEND',
      panel: 'UPPER-TAILGATE',
      subsetOf: ['UPPER TAILGATE IN & OUT', 'UPPER TAILGATE OUT'],
      settings: {
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 1.2,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.85,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE LOADING 10%',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 0.21,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE LOADING 25%',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 0.53,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'UPPER TAILGATE SEALERS NESS',
      panel: 'UPPER-TAILGATE',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON|VAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 25,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'PDR UPPER TAILGATE',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH UPPER TAILGATE',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH UPPER TAILGATE',
      settings: {
        'UPPER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
