const OUT = 1.40;


export default side => ({
  panelCode: 'B',
  lineItems: [
    {
      description: `${side} SLIDE DOOR`,
      panel: `${side}-SLIDE-DOOR`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.4,
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR OUT`,
      panel: `${side}-SLIDE-DOOR`,
      supersetOf: [`${side} SLIDE DOOR BLEND`],
      subsetOf: [`${side} SLIDE DOOR IN & OUT`],
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              'VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 4.1,
                  paintAutoPaintable: true,
                }
              },
            },
            '^(?!panel)': { /* pricing method */
              'VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: OUT,
                  paintAutoPaintable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '^VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 8.12,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '^VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 5.1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR IN & OUT`,
      panel: `${side}-SLIDE-DOOR`,
      supersetOf: [`${side} SLIDE DOOR OUT`, `${side} SLIDE DOOR BLEND`],
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 4.1,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.1,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR BLEND`,
      panel: `${side}-SLIDE-DOOR`,
      subsetOf: [`${side} SLIDE DOOR IN & OUT`, `${side} SLIDE DOOR OUT`],
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 4.1,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 4.1,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR LOADING 10%`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR LOADING 25%`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} SLIDE DOOR SEALERS NESS`,
      panel: `${side}-SLIDE-DOOR`,
      settings: {
        [`${side}-SLIDE-DOOR|${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 28.60,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `PDR ${side} SLIDE DOOR`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side} SLIDE DOOR`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side} SLIDE DOOR`,
      settings: {
        [`${side}-SLIDE-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
