const RR_FACTORS = require('./rr-factors');

module.exports = {
  lineItems: [
    {
      description: 'AUTO ELECTRICAL',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isSublet: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CARGO BARRIER',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.25,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'LHF APERTURE INC STRIKER & RESET',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.41,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'RHF APERTURE INC STRIKER & RESET',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.41,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'ADDITIONAL PDR REPAIRS',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CONVENTIONAL REPAIRS',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isSublet: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'FRONT SCREEN',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 150,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'GLASS REPLACEMENT',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isSublet: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'IN ROOF DVD PLAYER',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'REAR CABIN SAFETY CAGE',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.25,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'REAR CANOPY',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 2,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'REAR SCREEN',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 150,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'ROOF RACKS',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.25,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'RETAPE MOULD',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 20,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'R&R NECESSARY FOR PDR',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['COUPE'],
              }
            },
            'HATCH': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['HATCH'],
              }
            },
            'SEDAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['SEDAN'],
              }
            },
            'UTE2DR': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['UTE2DR'],
              }
            },
            'UTE4DR': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['UTE4DR'],
              }
            },
            'WAGON/5DR': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['WAGON/5DR'],
              }
            },
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: RR_FACTORS['VAN'],
              }
            },
          },
          },
        },
      }
    },
    {
      description: 'SPOILER',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.18,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'SUNROOF AND FITTINGS',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 2,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TAILGATE FULL',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WINDOW TINTING',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isSublet: true,
                subletPrice: 43.9,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WINDOWS FRONT & REAR IN & OUT',
      settings: {
        'NSI': {
          '^(?!PRIXCAR)': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.8,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'WINDOWS FRONT IN & OUT',
      settings: {
        'NSI': {
          '^PRIXCAR': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.8,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WINDOWS REAR IN & OUT',
      settings: {
        'NSI': {
          '^PRIXCAR': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.8,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WINDOWS FRONT & REAR R&R',
      settings: {
        'NSI': {
          '^EAGERS': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 3.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: '1/4 WINDOW R&R',
      settings: {
        'NSI': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.9,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 2.06,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'RIMS',
      settings: {
        'NSI': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 0.8,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WHEELARCH FLAIRS',
      settings: {
        'NSI': {
          '^(?!EAGERS)': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.9,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.37,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'MOULDS R&R',
      settings: {
        'NSI': {
          '^(?!EAGERS)': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'WHEEL ARCH FLAIRS',
      settings: {
        'NSI': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'PAINT WIPER ARM (L)',
      settings: {
        'NSI': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 100,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.25,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'PAINT WIPER ARM (R)',
      settings: {
        'NSI': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 100,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.25,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'RIM STRIP FIT AND BALANCE',
      settings: {
        'NSI': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CLAY GROOM',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 137,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH ALL CAR',
      settings: {
        'NSI': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 500,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH SPOT',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.81,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH PANEL',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.37,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.9,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'PAINT CONSUMABLES',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
                miscAutoPaintable: true,
                miscAutoReplaceable: true,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'PANEL CONSUMABLES',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'PAINT MATERIALS',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
                miscAutoPaintable: true,
                miscAutoReplaceable: true,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'CORROSION PROTECTION',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 25,
                miscAutoReplaceable: true,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'PAINT SETUP @ 33%',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
                miscAutoPaintable: true,
                miscAutoReplaceable: true,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'ENVIRONMENTAL LEVY',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 9.50,
                miscAutoPaintable: true,
                miscAutoReplaceable: true,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'WASH & VAC',
      settings: {
        'NSI': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 35,
              }
            }
          }
          },
        }
      }
    },
    {
      description: 'LH SILL COVER & CLIPS',
      settings: {
        'NSI': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.3,
                }
              }
            }
          },
        }
      }
    },
    {
      description: 'RH SILL COVER & CLIPS',
      settings: {
        'NSI': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.3,
                }
              }
            }
          },
        }
      }
    },
    {
      description: 'SILL COVER',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.5,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'LH REAR SPLIT DOOR',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 5,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3.2,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'RH REAR SPLIT DOOR',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 5,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3.2,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'G101 WASH',
      settings: {
        'NSI': {
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'FUEL FLAP',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.75,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'TEXTURED MOULDS',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 125,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  miscPrice: 75,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'PAINTED WHEELS',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.87,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'REAR PANEL',
      settings: {
        'NSI': {
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.7,
                hourlyRate: 100,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'REAR SPOILER',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.37,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 1.9,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'TOW HOOK FLAP',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.4,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'TOUCH UP',
      settings: {
        'NSI': {
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.62,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.4,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
  ]
};

