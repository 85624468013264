import React from 'react';
import {NavLink} from 'react-router-dom';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import Wrench from 'icons/wrench.jsx';
import colors from 'colors';
import LocationSelect from 'components/LocationSelect';


const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
  @media print {
    overflow: visible;
  }
`;
const Legend = styled.div`
  width: 75px;
  border-bottom: 3px solid ${opts => opts.color};
`;
const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Button = styled.button`
  & svg {
    stroke: #fff;
  }
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.empty ? 'inherit' : colors.grey1};
  box-shadow: ${opts => opts.open ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)' : 'none'};
  :last-of-type {
    margin-bottom: 0px !important;
  }
  @media print {
    display: ${opts => opts.open ? 'block' : 'none'};
  }
`;
const SectionHeader = styled.div`
  cursor: pointer;
`;
const SectionTitle = styled.h6`
  color: ${colors.primary};
`;
const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  border: 1px dashed ${opts => opts.modified ? colors.red : opts.color};
  @media print {
    border: 1px solid ${colors.body};
  }
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;
const OrmError = styled(Icons.AlertTriangle)`
  stroke: ${colors.red};
`;

export default class Tracking extends React.Component {
  constructor(opts) {
    super(opts);
    this.renderJob = this.renderJob.bind(this, opts.debtors);
  }

  componentDidMount() {
    const {locationId, onLoad} = this.props;
    if (locationId) {
      onLoad(locationId);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.locationId && this.props.locationId !== prevProps.locationId) {
      this.props.onLoad(this.props.locationId);
    }
  }

  onToggleSection(section) {
    this.props.onToggleSection(section);
  }

  renderJob(debtors, job, i) {
    const debtor = debtors[job.debtorId] || {name: 'N/A'};
    return (
      <div className='col-sm-6 col-md-4 col-lg-3 mt-2' key={i}>
        <JobCard
          className='d-flex flex-row mx-1 h-100'
          color={colors[job.alertColor || job.flag]}
          modified={job.assessorModified}
        >
          <div className='d-flex flex-column justify-content-between flex-grow-1 p-2'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div>
                <NavLink to={`/job/${job.id}`} className={job.hasOrmError === true ? 'text-danger font-weight-bold' : 'text-body font-weight-bold'}>{job.jobNumber}</NavLink> {job.hasOrmError === true && (
                <OrmError
                  className='ml-1'
                  width={14}
                  height={14}
                />
                )}
              </div>
              <div className='text-center'>{job.rego}</div>
              {job.showAssessmentTime !== true && job.showFitmentTime !== true && (
                <div className='text-right'>{job.startDateStr} | {job.finishDateStr}</div>
              )}
              {job.showAssessmentTime === true && (
                <div className='text-right'>{job.assessmentTime}</div>
              )}
              {job.showFitmentTime === true && (
                <div className='text-right'>{job.fitmentTime}</div>
              )}
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='flex-grow-1 text-truncate text-uppercase'>{job.makeModel}</div>
              <div className='d-flex flex-row'>
                <div className='ml-1'>
                  {debtor.name}
                  {' '}
                  {job.showContacted === true && job.numTimesContacted}
                  {job.showContacted !== true && job.showAssessmentTime !== true && job.showFitmentTime !== true && job.category}
                </div>
                {job.showNeedsPartsFlag === true && (
                  <Wrench width={14} height={14} className='ml-1' color={colors.red} />
                )}
                {(job.outOfScope || job.autoOrmEnabled !== true) && (
                  <Icons.AlertOctagon width={14} height={14} className='ml-1' color={colors.red} />
                )}
                {job.showZeroValueFlag === true && (
                  <Icons.DollarSign width={14} height={14} className='ml-1' color={colors.red} />
                )}
              </div>
            </div>
          </div>
          {job.image64 && <JobImg src={job.image64} className='m-2 d-print-none' />}
        </JobCard>
      </div>
    );
  }

  renderSection({title, key, jobs}, i) {
    const {sectionStates} = this.props;
    const open = (sectionStates[key] === true);
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;
    if (jobs.length === 0) {
      return (
        <Section key={i} className='p-2 mb-2' empty={true}>
          <h6 className='mb-0 mx-1'>{title} (0)</h6>
        </Section>
      );
    }
    return (
      <Section key={i} className='p-2 mb-2' open={open}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center'
          onClick={this.onToggleSection.bind(this, key)}
        >
          <SectionTitle className='mb-0 mx-1'>{title} ({jobs.length})</SectionTitle>
          <Chevron color={colors.primary} />
        </SectionHeader>
        <div className='row no-gutters'>
          {open === true && jobs.map(this.renderJob)}
        </div>
      </Section>
    );
  }

  render() {
    console.log('tracking:render');
    const {notFound, sections, onSave} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to jobs.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Tracking</h5>
          <div className='d-flex flex-row align-items-center'>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.red}
            ><small>Flagged</small></Legend>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.orange}
            ><small>VPP</small></Legend>
            <Legend
              className='d-none d-lg-block mr-4 text-center text-muted'
              color={colors.yellow}
            ><small>VIP</small></Legend>

            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.greenExtraLight}
            ><small>Parts received</small></Legend>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.purpleExtraLight}
            ><small>Parts ordered</small></Legend>
            <Legend
              className='d-none d-lg-block mr-4 text-center text-muted'
              color={colors.orangeExtraLight}
            ><small>Has parts</small></Legend>

            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.blueExtraLight}
           ><small>Contacted</small></Legend>
            <Legend
              className='d-none d-lg-block mr-4 text-center text-muted'
              color={colors.tealExtraLight}
            ><small>SMS sent</small></Legend>
            <LocationSelect />
            <Button
              type='button'
              className='btn btn-secondary px-3 ml-2 d-none d-lg-block'
              disabled={notFound !== false}
              onClick={onSave.bind(null, sections)}
            ><Icons.Download width={18} height={18} /></Button>
          </div>
        </div>
        {notFound === false && sections.map(this.renderSection.bind(this))}
      </Container>
    );
  }
};
