const OUT = 1.51;


module.exports = {
  panelCode: 'A',
  lineItems: [
    {
      description: 'TAILGATE',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE SPOILER',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.19,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE MOULD',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.14,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE SEAL',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.07,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE LOCK/HATCH',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.07,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE PART D/A',
      subsetOf: ['TAILGATE D/A'],
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.54,
                rrAutoPaintable: true,
              }
            },
          },
          },
        },
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.54,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        },
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.54,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE D/A',
      supersetOf: ['TAILGATE PART D/A'],
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1.78,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'INNER BOOT TRIMS NESS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'INNER TAILGATE TRIMS NESS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'UTE2DR|UTE4DR|VAN': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE OUT',
      panel: 'TAILGATE',
      supersetOf: ['TAILGATE BLEND'],
      subsetOf: ['TAILGATE IN & OUT'],
      settings: {
        'TAILGATE': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              'COUPE|UTE|VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.4,
                  paintAutoPaintable: true,
                }
              },
            },
            '^(?!panel)': { /* pricing method */
              'COUPE|UTE|VAN': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: OUT,
                  paintAutoPaintable: true,
                }
              },
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 5.62,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 4.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'TAILGATE IN & OUT',
      panel: 'TAILGATE',
      supersetOf: ['TAILGATE OUT', 'TAILGATE BLEND'],
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.4,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.30,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE BLEND',
      panel: 'TAILGATE',
      subsetOf: ['TAILGATE IN & OUT', 'TAILGATE OUT'],
      settings: {
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.4,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE LOADING 10%',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE LOADING 25%',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE SEALERS NESS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|UTE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 37,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE GLASS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 135,
                miscAutoReplaceable: true,
                miscAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'SPECIAL TAPE TAILGATE GLASS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|VAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
                miscAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LH & RH QTR GLASS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                miscPrice: 270,
                miscAutoPaintable: true,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BAR',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              'COUPE': { /* body shape */
                '^M[1-3]$': { /* paint type */
                  rrTime: 0.38,
                  rrAutoPaintable: true,
                  rrAutoReplaceable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 4.37,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'LH & RH TAIL LIGHTS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.24,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LH & RH SPLASH SHEILDS',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.22,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TURRET MOULDS X2',
      panel: 'TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.2,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'PDR TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH TAILGATE',
      settings: {
        'TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
