const OUT = 1.67;


module.exports = {
  panelCode: 'A',
  lineItems: [
    {
      description: 'REAR BUMPER',
      panel: 'REAR-BUMPER',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER PART D/A',
      panel: 'REAR-BUMPER',
      subsetOf: ['REAR BUMPER RR'],
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.67,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
        'BOOT|TAILGATE|LHR-DOOR|RHR-DOOR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.67,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
        },
        }
      }
    },
    {
      description: 'REAR BUMPER RR',
      panel: 'REAR-BUMPER',
      supersetOf: ['REAR BUMPER PART D/A'],
      settings: {
        'REAR-BUMPER': {
          '^(?!EAGERS)': {
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 0.38,
                  rrAutoReplaceable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.87,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER OUT',
      panel: 'REAR-BUMPER',
      settings: {
        'REAR-BUMPER': {
          '^(?!(EAGERS|PRIXCAR))': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3,
                  paintAutoPaintable: true,
                  paintAutoReplaceable: true,
                }
              }
            },
            '^(?!panel)': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: OUT,
                  paintAutoPaintable: true,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  paintTime: 4.75,
                  hourlyRate: 100,
                }
              }
            },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  paintTime: 3.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER LOWER',
      panel: 'REAR-BUMPER',
      settings: {
        'REAR-BUMPER': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
              }
            }
          },
          },
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
                hourlyRate: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER UPPER',
      panel: 'REAR-BUMPER',
      settings: {
        'REAR-BUMPER': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.1,
              }
            }
          },
          },
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.1,
                hourlyRate: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER LOADING 10%',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER LOADING 25%',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                partPrice: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR BUMPER ADJUSTMENTS',
      settings: {
        'REAR-BUMPER': {
          '^(?!EAGERS)': {
            '^panel$': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                }
              }
            },
          },
          '^EAGERS': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1.87,
                  hourlyRate: 100,
                }
              }
            },
          },
        },
      }
    },
    {
      description: 'SPOILER ADJUSTMENTS',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'PDR REAR BUMPER',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH REAR BUMPER',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH REAR BUMPER',
      settings: {
        'REAR-BUMPER': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
